.life-improvements {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 0px !important;
}

.improvement {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  flex-direction: row;
}

.improvement img {
  width: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .improvement {
    flex-direction: column;
    text-align: start !important;
  }

  .improvement img {
    width: 100%;
    margin: 0 auto;
  }
}
