.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  width: 65%;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px;
  border-radius: 25px;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
}

.message p {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.5;
  text-align: start;
  justify-content: start;
  padding-left: 70px;
  width: 90%;
}

.message a {
  color: #1e90ff;
  text-decoration: none;
}

.input-box {
  display: flex;
  justify-content: center; /* Center the input box */
  margin-top: 20px;
  width: 100%;
}

.input-box input {
  flex: 1;
  max-width: 80%; /* Reduce the width of the input */
  padding: 10px;
  border: 2px solid rgba(69, 167, 245, 1);
  border-radius: 16px;
}

.input-box .MuiIconButton-root {
  padding: 10px;
}
