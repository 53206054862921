.pricing-plans {
  text-align: center;
  font-family: Arial, sans-serif;
}

.plans {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.plan {
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 30px;
  width: 270px;
  text-align: start;
}

.plan h3 {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.plan p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.plan button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.plan button:hover {
  background-color: #0056b3;
}

.plan ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.plan ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.plan ul li svg {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .plans {
    flex-direction: column;
    gap: 20px;
  }
}
