.about-mia {
  font-family: Arial, sans-serif;
}

.feature-title {
  color: white;
  background-color: rgba(69, 167, 245, 1);
  border-radius: 4px;
  padding: 6px 10px;
  display: inline-block;
  margin-bottom: 10px !important;
}

.feature-box {
  padding: 10px;
  text-align: start;
  background-color: white;
  border-radius: 8px;
}
